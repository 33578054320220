import { template as template_77c04580b6204528a9c8d67e3f8315ad } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_77c04580b6204528a9c8d67e3f8315ad(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
