import { template as template_03f9feb6d5774159ba0f7f2d36ab2dba } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_03f9feb6d5774159ba0f7f2d36ab2dba(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
