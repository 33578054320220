import { template as template_97faf793475945fb99350d1760fcc1bb } from "@ember/template-compiler";
const FKLabel = template_97faf793475945fb99350d1760fcc1bb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
