import { template as template_90ce581b94a24ff0b6de50a86328d52a } from "@ember/template-compiler";
const FKControlMenuContainer = template_90ce581b94a24ff0b6de50a86328d52a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
