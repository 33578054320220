import { template as template_cae49edc5b4b4e5694deb5467895eae5 } from "@ember/template-compiler";
const WelcomeHeader = template_cae49edc5b4b4e5694deb5467895eae5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
